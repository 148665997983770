import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ICurrency, ILanguage, IOrder, IUser } from '../../../models/strapi.models';
import { fetchStrapi } from '../../../services/api.service';
import { getMe, getStoredUserToken, logOut } from '../../../services/auth.service';
import { logError, logInfo } from '../../../services/log.service';
import { getStoredData, storeData } from '../../../services/storage.service';
import { getAppCurrencies, getAppLanguages, getDefaultCurrency, getDefaultLocale } from '../../../shared/useCommon';
import { IAppPage, IAppPageLocalization, setCurrencies, setCurrentCurrency, setCurrentLocale, setLanguages, setPages, setPendingOrdersCount } from '../../../store/slices/appSlice';
import { setIsAuthenticated, setUser } from '../../../store/slices/authSlice';
import { recalculatePrices } from '../../../store/slices/currentOrderSlice';
import { ICookiesPopupProps } from '../CookiesPopUp';
import routes from './../../../scripts/routes.json';
import Footer, { IFooterProps } from './Footer';
import { ILoadingOverlayProps } from './LoadingOverlay';
import NavBar, { INavBarProps } from './NavBar';

interface LayoutProps {
  locale: string;
  contentLanguageCode: string;
  navBarProps: INavBarProps;
  cookiesPopUpProps: ICookiesPopupProps;
  footerProps: IFooterProps;
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const authState = useAppSelector((state) => state.auth);
  const isLoading = useAppSelector((state) => state.app.isLoading);
  const pages: IAppPage[] = useMemo(() => routes || [], []);

  // Layout states
  const [cookiesPopupShow, setCookiesPopupShow] = useState<boolean>(false);

  const localeSwitcher = useMemo<{ switched: boolean }>(() => ({ switched: false }), []);

  // Components props declaration
  const loadingOverlayProps: ILoadingOverlayProps = {
    show: isLoading,
  };

  const cookiesPopUpProps: ICookiesPopupProps = {
    ...props.cookiesPopUpProps,
    primaryButtonOnClick: () => {
      storeData(
        {
          accepted: true,
        },
        'cookies-acceptance',
        true
      );

      setCookiesPopupShow(false);
    },
    secondaryButtonOnClick: () => window.open(getRoute('/cookies-policy'), '_system'),
  };

  const navBarProps: INavBarProps = useMemo(
    () => ({
      ...props.navBarProps,
      appCurrentCurrency: appState.currentCurrency || { isoCode: 'USD' },
      appLocale: appState.currentLocale || 'en',
      onUserLogout: async () => await logout(),
      onLocaleChange: (languageCode: string, source: 'use-effect' | 'nav-bar', route?: { path: string; slug?: string }) => changeLocale(languageCode, source, route),
      onCurrencyChange: (currency: ICurrency) => changeCurrency(currency),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.currentCurrency, appState.currentLocale, props.navBarProps]
  );

  const getRoute = useCallback(
    (actualPath: string, language?: string, slug?: string) => {
      let localizedRoute = actualPath;

      // If has the placeholder for the slug, replace it
      if (!!slug) {
        localizedRoute = localizedRoute.replace('[slug]', slug);
      }

      if (language && localizedRoute.includes('/blog/')) {
        // Process for blog posts
        // Here, the slug is the blog post id
        const page = pages.find((x: IAppPage) => {
          return x.postId === parseInt(slug || '0');
        });

        if (page) {
          // Get its alternative page (localization) based on language
          const altPage = page?.localizations?.find((x: IAppPageLocalization) => x.locale === language);

          if (altPage) {
            // Look for the localized page
            const localizedPage = pages.find((x: IAppPage) => {
              return x.postId === altPage.id;
            });

            if (localizedPage) {
              localizedRoute = localizedPage.source;
            }
          }
        }
      } else {
        // Process for service pages
        const originalPath = getOriginalPathBySlug(pages, slug);

        if (!!originalPath) {
          const sourcePath = getSourceByOriginalPath(pages || [], originalPath, language || router.locale || 'en');

          if (!!sourcePath) {
            localizedRoute = sourcePath;
          }
        }

        const localizedPage = pages.find((x: IAppPage) => x && x.destination === actualPath && (language ? x.language === language : x.language === router.locale));

        if (localizedPage) {
          localizedRoute = localizedPage.source;
        }
      }

      logInfo([{ actualPath, language, slug, localizedRoute }], 'Layout → Localized route');

      return localizedRoute;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pages, router.locale]
  );

  const getOriginalPathBySlug = (pages: IAppPage[], slug?: string) => {
    if (!slug) return undefined;
    return pages.find((x) => x.destination.includes(slug))?.original || undefined;
  };

  const getSourceByOriginalPath = (pages: IAppPage[], originalPath: string, language: string) => {
    return pages.find((x) => x.original === originalPath && x.language === language)?.source || undefined;
  };

  const setAppLocale = useCallback(
    (languageCode: string) => {
      dispatch(setCurrentLocale(languageCode));
      logInfo([languageCode], 'App → Locale');
    },
    [dispatch]
  );

  const changeLocale = useCallback(
    async (locale: string, source: 'use-effect' | 'nav-bar', route?: { path: string; slug?: string }) => {
      const canSwitch = locale !== router.locale && (localeSwitcher.switched === false || source === 'nav-bar');

      // Store user defined locale
      storeData({ code: locale }, 'user-locale');

      // Set app locale
      if (appState.currentLocale !== locale) {
        setAppLocale(locale);
      }

      if (canSwitch) {
        const destination = route?.path || router.asPath;
        const localizedRoute = getRoute(destination, locale, route?.slug as string);

        let navigated = false;

        try {
          navigated = await router.push(localizedRoute, localizedRoute, {
            locale: locale,
          });
        } catch (error) {
          logInfo([{ ...(error as any) }], 'App → Preventing unnecesary navigation');
        }

        logInfo(
          [
            { languageCode: locale, source, route },
            { destination, localizedRoute },
          ],
          'App → Change locale'
        );

        return navigated;
      } else {
        logInfo(['Navigation prevented', { source }], 'App → Change locale');
        return true;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getRoute, localeSwitcher.switched, router, setAppLocale]
  );

  const loginOnBehalfOfUser = useCallback(async () => {
    if (!authState.isAuthenticated) {
      const storedUserToken = getStoredUserToken();

      if (storedUserToken) {
        try {
          const user = await getMe();
          dispatch(setIsAuthenticated(user !== undefined));
          dispatch(setUser(user));
          logInfo([user], 'App → User');
          return user;
        } catch (error) {
          logError([error], 'App → User');
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  }, [authState.isAuthenticated, dispatch]);

  const logout = useCallback(async () => {
    const notAllowedRoutes = ['account', 'bank-wire-checkout-result', 'multiple-orders-payment', 'credit-card-checkout-result', 'cart'];

    logOut();

    if (notAllowedRoutes.includes(router.asPath.split('/')[1])) {
      await router.push('/');
    }

    router.reload();
  }, [router]);

  const recalculateOngoingOrder = useCallback(
    (currency: ICurrency) => {
      dispatch(recalculatePrices(currency));
    },
    [dispatch]
  );

  const changeCurrency = useCallback(
    (currency: ICurrency) => {
      storeData({ code: currency.isoCode }, 'user-currency');
      dispatch(setCurrentCurrency(currency));
      logInfo([currency], 'App → Currency');
      recalculateOngoingOrder(currency);
    },
    [dispatch, recalculateOngoingOrder]
  );

  const getOrders = useCallback(async () => {
    try {
      const ordersResponse: IOrder[] = await fetchStrapi('/orders/mine');
      const pendingOrders: IOrder[] = ordersResponse.filter((x) => x.status === 'draft');

      dispatch(setPendingOrdersCount(pendingOrders.length));
      logInfo(['Response', pendingOrders], 'App → getOrders');
    } catch (error) {
      logError(['Error', error], 'App → getOrders');
    }
  }, [dispatch]);

  const populateAppPages = useCallback(async () => {
    dispatch(setPages(pages));
    logInfo([pages ? pages.length : 0], 'App → App pages count');
  }, [dispatch, pages]);

  useEffect(() => {
    // Populate app pages based on rewrites generated on pre-build
    populateAppPages();
    getAppLanguages().then((languages: ILanguage[]) => {
      dispatch(setLanguages(languages));
      logInfo([languages], 'App → Languages');
    });

    // Set cookies popup show state
    const acceptance = getStoredData('cookies-acceptance') as {
      accepted: boolean;
    };

    setCookiesPopupShow(!!acceptance ? !acceptance.accepted : true);
  }, [dispatch, populateAppPages]);

  useEffect(() => {
    // Try to recover user session
    loginOnBehalfOfUser().then((user: IUser | undefined) => {
      if (!!user) {
        logInfo([user], 'App');
        getOrders();
      }

      const storedLocale = getStoredData<{ code: string }>('user-locale')?.code;
      const defaultLocale = getDefaultLocale(router.locale || 'en', storedLocale);

      changeLocale(defaultLocale, 'use-effect').then((navigated: boolean) => {
        localeSwitcher.switched = navigated;
      });

      getAppCurrencies().then(async (currencies: ICurrency[]) => {
        dispatch(setCurrencies(currencies));

        if (user?.user_info?.currency) {
          changeCurrency(user.user_info.currency);
        } else {
          const defaultCurrency = await getDefaultCurrency(currencies);
          if (defaultCurrency) {
            changeCurrency(defaultCurrency);
          }
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.locale, dispatch]);

  const DynamicLoadingOverlay = dynamic(() => import('./LoadingOverlay'), {
    ssr: false,
  });

  const DynamicCookiesPopUp = dynamic(() => import('../CookiesPopUp'), {
    ssr: false,
  });

  const disallowIndexation = process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production';

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0" />
        {disallowIndexation && <meta name="robots" content="noindex,nofollow" />}
        {disallowIndexation && <meta name="googlebot" content="noindex,nofollow" />}
        <meta httpEquiv="content-language" content={props.contentLanguageCode} />
      </Head>
      <NavBar {...navBarProps} />
      <main className="main-container">{props.children}</main>
      <Footer {...props.footerProps} />
      <DynamicLoadingOverlay {...loadingOverlayProps} />
      {cookiesPopupShow && <DynamicCookiesPopUp {...cookiesPopUpProps} />}
    </>
  );
};

export default Layout;
