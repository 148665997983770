import Image from 'next/image';
import { useRouter } from 'next/router';
import { useAppSelector } from '../../../hooks';
import { IMainFooter } from '../../../models/strapi.models';
import { IAppPage } from '../../../store/slices/appSlice';
import { FooterStyle } from '../../../styles/components/common/layout';

export interface IFooterProps {
  items?: IMainFooter;
  contactMethods?: string[];
}

const Footer = (props: IFooterProps) => {
  const router = useRouter();
  const appPages = useAppSelector((state) => state.app.pages);
  const currentYear: number = new Date().getFullYear();

  const getRoute = (actualPath: string) => {
    return appPages?.find((x: IAppPage) => x && x.destination === actualPath && x.language === router.locale)?.source || actualPath;
  };

  return (
    <div className={FooterStyle.footer}>
      <div className={FooterStyle['footer-content-block']}>
        <div className={`${FooterStyle['footer-content-slot']} ${FooterStyle['slot-1']}`}>
          <div className={FooterStyle['footer-logo']}>
            <Image src={'/assets/igerent-logo-sq.svg'} alt="iGerent footer logo" priority={false} width={39} height={39} />
          </div>
        </div>
        <div className={`${FooterStyle['footer-content-slot']} ${FooterStyle['slot-2']}`}>
          <h3>{props.items?.servicesLabel}</h3>
          <ul>
            {props.items?.servicesItems.map((value, index) => {
              return (
                <li key={index}>
                  <a href={getRoute(value.path || '/')}>{value.label}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={`${FooterStyle['footer-content-slot']} ${FooterStyle['slot-3']}`}>
          <h3>{props.items?.contactLabel}</h3>
          <ul>
            {props.contactMethods?.map((value, index) => {
              return <li key={index}>{value}</li>;
            })}
          </ul>
          <div className={FooterStyle['footer-social-icons']}>
            <span onClick={() => window.open('https://www.facebook.com/igerent.ip', '_system')}>
              <Image src="/assets/images/icons/FacebookIcon.svg" priority={false} alt="Facebook" width={21} height={21} />
            </span>
            <span onClick={() => window.open('https://twitter.com/igerent', '_system')}>
              <Image src="/assets/images/icons/TwitterIcon.svg" priority={false} alt="Twitter" width={21} height={21} />
            </span>
            <span onClick={() => window.open('https://linkedin.com/company/igerent', '_system')}>
              <Image src="/assets/images/icons/LinkedinIcon.svg" priority={false} alt="Linkedin" width={21} height={21} />
            </span>
          </div>
        </div>
        <div className={`${FooterStyle['footer-content-slot']} ${FooterStyle['slot-4']}`}>
          <div className={FooterStyle['footer-payments-title']}>
            <Image src={'/assets/images/icons/ShieldIcon.svg'} priority={false} width={12} height={15} alt="Pay safely" />
            <h3>{props.items?.paymentLabel}</h3>
          </div>
          <div className={FooterStyle['footer-payments-methods']}>
            <Image src={'/assets/images/common/payment-methods.svg'} priority={false} width={282} height={25} alt="Pay safely" />
          </div>
          <div className={FooterStyle['footer-payments-methods']}>
            <Image src={'/assets/images/common/ecommerce-badges.svg'} priority={false} width={282} height={63} alt="Pay safely" />
          </div>
        </div>
      </div>
      <div className={FooterStyle['footer-copyright']}>
        <div className={`${FooterStyle['footer-content-block']} ${FooterStyle['narrow']}`}>
          <div className={`${FooterStyle['footer-content-bottom-slot']} ${FooterStyle['slot-1']} is-self-align-center`}>
            © {currentYear} {props.items?.copyright}
          </div>
          <div className={`${FooterStyle['footer-content-bottom-slot']} ${FooterStyle['slot-2']} is-self-align-center is-text-align-right`}>
            <a href={getRoute(props.items?.cookiesPolicyItem.path || '/')}>{props.items?.cookiesPolicyItem.label}</a>
            <a href={getRoute(props.items?.privacyItem.path || '/')}>{props.items?.privacyItem.label}</a>
            <a href={getRoute(props.items?.termsItem.path || '/')}>{props.items?.termsItem.label}</a>
          </div>
        </div>
        <div className={`${FooterStyle['footer-content-block']} ${FooterStyle['narrow']} ${FooterStyle['footer-disclaimer']} is-text-align-center`}>{props.items?.disclaimer}</div>
      </div>
    </div>
  );
};

export default Footer;
